import React, { useState, useEffect } from "react";
import Tippy, { useSingleton } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import axios from "axios";

// -------------------------------------
// 1) Enhanced LDOCE-Style Component (Tailwind + JSX version)
// -------------------------------------
function LDOCEStyleEntry({ entry }) {
  if (!entry) return null;

  return (
    <div className="mx-auto max-w-4xl p-8 font-sans leading-relaxed text-gray-800">
      {/* Pronunciations */}
      {entry.pronunciations && (
        <section className="mb-6">
          <h2 className="relative mb-4 mt-8 border-b-2 border-indigo-300 pb-2 pl-4 text-2xl font-bold text-indigo-600">
            <span className="absolute -top-3 left-0 text-indigo-400">◆</span>
            Pronunciations:
          </h2>
          <div className="pl-8">
            <p className="text-lg">
              <strong className="font-semibold text-gray-900">
                UK English IPA:
              </strong>{" "}
              {entry.pronunciations.uk}
            </p>
            <p className="text-lg">
              <strong className="font-semibold text-gray-900">
                US English IPA:
              </strong>{" "}
              {entry.pronunciations.us}
            </p>
          </div>
        </section>
      )}

      {/* Parts of Speech and Meanings */}
      {entry.partsOfSpeech &&
        entry.partsOfSpeech.map((posEntry, posIndex) => (
          <div key={posIndex} className="mb-6">
            <h2 className="relative mb-4 mt-8 border-b-2 border-indigo-300 pb-2 pl-4 text-2xl font-bold text-indigo-600">
              <span className="absolute -top-3 left-0 text-indigo-400">◆</span>
              {posEntry.pos}
            </h2>
            {posEntry.meanings &&
              posEntry.meanings.map((meaning, meaningIndex) => (
                <div key={meaningIndex} className="mb-4 pl-8">
                  <h3 className="text-xl font-semibold mb-2">
                    Meaning {meaningIndex + 1} ({posEntry.pos})
                  </h3>
                  <p className="mb-2 text-lg">{meaning.definition}</p>
                  {meaning.examples && (
                    <ul className="list-disc list-inside ml-4 mb-2">
                      {meaning.examples.map((example, exIndex) => (
                        <li key={exIndex} className="text-base text-gray-600">
                          <span className="font-bold">
                            Sentence {String.fromCharCode(65 + exIndex)}:
                          </span>{" "}
                          {example}
                        </li>
                      ))}
                    </ul>
                  )}
                  {meaning.synonyms && meaning.synonyms.length > 0 && (
                    <p className="text-base text-gray-600 mb-2">
                      <strong className="font-semibold">Synonyms:</strong>{" "}
                      {meaning.synonyms.join(", ")}
                    </p>
                  )}
                  {meaning.usage && (
                    <p className="text-sm text-gray-500">
                      <strong className="font-semibold">Usage note:</strong>{" "}
                      {meaning.usage}
                    </p>
                  )}
                </div>
              ))}
          </div>
        ))}

      {/* Etymology */}
      {entry.etymology && (
        <section className="mb-6">
          <h2 className="relative mb-4 mt-8 border-b-2 border-indigo-300 pb-2 pl-4 text-2xl font-bold text-indigo-600">
            <span className="absolute -top-3 left-0 text-indigo-400">◆</span>
            Etymology:
          </h2>
          <p className="pl-8 text-lg">{entry.etymology}</p>
        </section>
      )}

      {/* Additional Information */}
      {entry.additional && typeof entry.additional === "object" && (
        <section className="mb-6">
          <h2 className="relative mb-4 mt-8 border-b-2 border-indigo-300 pb-2 pl-4 text-2xl font-bold text-indigo-600">
            <span className="absolute -top-3 left-0 text-indigo-400">◆</span>
            Additional Information:
          </h2>
          <div className="pl-8 text-lg">
            {Object.entries(entry.additional).map(([key, value]) => (
              <p key={key}>
                <strong className="font-semibold">{key}:</strong>{" "}
                {Array.isArray(value) ? value.join(", ") : value}
              </p>
            ))}
          </div>
        </section>
      )}

      {entry.additional && typeof entry.additional !== "object" && (
        <section className="mb-6">
          <h2 className="relative mb-4 mt-8 border-b-2 border-indigo-300 pb-2 pl-4 text-2xl font-bold text-indigo-600">
            <span className="absolute -top-3 left-0 text-indigo-400">◆</span>
            Additional Information:
          </h2>
          <p className="pl-8 text-lg">{entry.additional}</p>
        </section>
      )}
    </div>
  );
}

// -------------------------------------
// 2) Main Dictionary Component
// -------------------------------------
const Dictionary = () => {
  const [text, setText] = useState("");
  const [chosenWord, setChosenWord] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [searchType, setSearchType] = useState("");
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [aiEntry, setAiEntry] = useState(null);
  const [isAiLoading, setIsAiLoading] = useState(false);

  // Tippy singletons for hover popups
  const [source, target] = useSingleton({ overrides: ["interactive"] });

  // -------------------------------------
  // 3) AI Fetching Logic (JSON response)
  // -------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      if (searchType !== "AI") return;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/chat/completions`,
          {
            model: "qwen/qwen-2.5-7b-instruct",
            messages: [
              {
                role: "user",
                content: `
Role: Expert English Teacher, Lexicographer, and Dictionary Editor
You are an authoritative source on English language definitions and usage. Write a comprehensive dictionary entry for the word or phrase "${chosenWord}" in the style of the Longman Dictionary of Contemporary English.
Please provide the entry as a JSON object with the following structure:

{
  "pronunciations": {
    "uk": "UK English IPA pronunciation",
    "us": "US English IPA pronunciation"
  },
  "partsOfSpeech": [
    {
      "pos": "Part of Speech (e.g., noun, verb, adjective)",
      "meanings": [
        {
          "definition": "A succinct definition with less than 30 words. Bold every occurrence of the word '${chosenWord}'",
          "examples": [
            "Sentence A: Bold '${chosenWord}' and include part of speech in parentheses.",
            "Sentence B: Bold '${chosenWord}' and include part of speech in parentheses."
          ],
          "synonyms": ["synonym1", "synonym2"],
          "usage": "Usage note (register, regional, etc.)"
        }
      ]
    }
  ],
  "etymology": "A brief etymology or word origin.",
  "additional": {
    "frequency": "Common",
    "commonCollocations": ["collocation1", "collocation2"],
    "derivativeForms": ["form1", "form2"]
  }
}

Ensure the JSON is properly formatted.
                `,
              },
            ],
          }
        );

        // Parse JSON response from AI
        const aiJson = JSON.parse(
          response.data.choices?.[0]?.message?.content || "{}"
        );
        setAiEntry(aiJson);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        setAiEntry({ error: "Failed to fetch AI response." });
      } finally {
        setIsAiLoading(false);
      }
    };

    fetchData();
  }, [chosenWord, searchType, reloadKey]);

  // Clean punctuation from the chosen word
  const cleanWord = (word) =>
    word.replace(/[.,/#!$%^&*;:@{}=\-_`~()]/g, "");

  // Handlers for each button
  const handleAIClick = (rawWord) => {
    setIsAiLoading(true);
    const word = cleanWord(rawWord);
    setChosenWord(word);
    setSearchType("AI");
    setReloadKey((prev) => prev + 1);
  };

  const handleDefinitionClick = (rawWord) => {
    setIsIframeLoading(true);
    const word = cleanWord(rawWord);
    setChosenWord(word);
    setSearchType("definitions");
    setReloadKey((prev) => prev + 1);
  };

  const handleCollocationClick = (rawWord) => {
    setIsIframeLoading(true);
    const word = cleanWord(rawWord);
    setChosenWord(word);
    setSearchType("collocations");
    setReloadKey((prev) => prev + 1);
  };

  const handleButtonClick = (event, rawWord, type) => {
    event.stopPropagation();
    const cleanedWord = cleanWord(rawWord);
    if (type === "definitions") {
      handleDefinitionClick(cleanedWord);
    } else if (type === "collocations") {
      handleCollocationClick(cleanedWord);
    } else {
      handleAIClick(cleanedWord);
    }
    // Hide the Tippy popup after clicking
    const tippyRoot = event.currentTarget.closest("[data-tippy-root]");
    if (tippyRoot && tippyRoot._tippy) {
      tippyRoot._tippy.hide();
    }
  };

  const renderTippyWords = () => {
    const trimmed = text.trim();
    if (!trimmed) {
      return (
        <span className="text-gray-400">
          Hoverable/clickable text will appear here...
        </span>
      );
    }
    const words = trimmed.split(/\s+/);
    return (
      <p className="m-0 whitespace-normal break-words text-lg">
        {words.map((word, index) => (
          <Tippy
            key={index}
            singleton={target}
            interactive={true}
            trigger="mouseenter click"
            content={
              <div className="flex items-center gap-3 p-2">
                <button
                  onClick={(e) => handleButtonClick(e, word, "definitions")}
                  className="rounded-full bg-gradient-to-r from-indigo-500 to-blue-500 px-4 py-1 text-white font-medium shadow hover:from-indigo-600 hover:to-blue-600 transition"
                >
                  Definitions
                </button>
                <button
                  onClick={(e) => handleButtonClick(e, word, "collocations")}
                  className="hidden md:inline rounded-full bg-gradient-to-r from-green-500 to-teal-500 px-4 py-1 text-white font-medium shadow hover:from-green-600 hover:to-teal-600 transition"
                >
                  Collocations
                </button>
                <button
                  onClick={(e) => handleButtonClick(e, word, "AI")}
                  className="rounded-full bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-1 text-white font-medium shadow hover:from-purple-600 hover:to-pink-600 transition"
                >
                  AI
                </button>
              </div>
            }
          >
            <span className="mr-2 inline-block cursor-pointer text-indigo-600 hover:underline font-semibold">
              {word}
            </span>
          </Tippy>
        ))}
      </p>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-xl p-8 mb-10">
        <h1 className="text-4xl font-extrabold text-center text-indigo-700 mb-4">
          Word Click Dictionary
        </h1>
        <p className="text-center text-lg text-gray-600">
          Paste or type your text into the box below. Then hover or click any word
          to see its dictionary definition, synonyms, usage notes, and more.
        </p>
      </div>

      {/* Main Content: Two-Column Layout */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column: Input Area */}
        <div className="bg-white rounded-xl shadow-xl p-8">
          <textarea
            placeholder="Paste or type your text here..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="h-80 w-full resize-none rounded-xl border border-gray-300 p-4 text-base shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <div className="mt-4 p-4 bg-gray-100 rounded-xl border border-gray-200">
            <Tippy
              singleton={source}
              popperOptions={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, 0] },
                  },
                ],
              }}
            >
              {renderTippyWords()}
            </Tippy>
          </div>
        </div>

        {/* Right Column: Results Section */}
        <div className="bg-white rounded-xl shadow-xl p-8">
          {chosenWord ? (
            <h2 className="text-2xl font-bold text-indigo-700 mb-2 text-center">
              Your chosen word:{" "}
              <span className="text-indigo-500">{chosenWord}</span>
            </h2>
          ) : (
            <h2 className="text-xl font-semibold text-gray-600 text-center mb-4">
              Choose and click a word from the left.
            </h2>
          )}

          <div className="flex justify-center gap-4 mb-6">
            <button
              onClick={() => chosenWord && handleDefinitionClick(chosenWord)}
              disabled={!chosenWord}
              className={`rounded-full px-6 py-2 font-semibold transition ${
                chosenWord
                  ? "bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:from-indigo-600 hover:to-blue-600"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              Definitions
            </button>
            <button
              onClick={() => chosenWord && handleCollocationClick(chosenWord)}
              disabled={!chosenWord}
              className={`rounded-full px-6 py-2 font-semibold transition ${
                chosenWord
                  ? "bg-gradient-to-r from-green-500 to-teal-500 text-white hover:from-green-600 hover:to-teal-600"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              Collocations
            </button>
            <button
              onClick={() => chosenWord && handleAIClick(chosenWord)}
              disabled={!chosenWord}
              className={`rounded-full px-6 py-2 font-semibold transition ${
                chosenWord
                  ? "bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:from-purple-600 hover:to-pink-600"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            >
              AI
            </button>
          </div>

          {searchType === "definitions" ||
          searchType === "collocations" ? (
            <iframe
              key={reloadKey}
              src={
                searchType === "definitions"
                  ? `https://www.merriam-webster.com/dictionary/${chosenWord}`
                  : `https://www.freecollocation.com/search?word=${chosenWord}`
              }
              height="500"
              allowFullScreen
              onLoad={() => setIsIframeLoading(false)}
              className="w-full rounded-xl border border-gray-300 shadow-md"
              title="Dictionary"
            />
          ) : searchType === "AI" ? (
            <div className="w-full bg-gray-50 rounded-xl border border-gray-200 p-4 shadow-md">
              <p className="mb-4 text-lg font-semibold text-gray-700">
                AI Definition:
              </p>
              {aiEntry && !aiEntry.error ? (
                <LDOCEStyleEntry entry={aiEntry} />
              ) : (
                <p className="text-red-500">
                  {aiEntry?.error || "Loading..."}
                </p>
              )}
            </div>
          ) : (
            <div className="w-full bg-gray-50 rounded-xl border border-gray-200 shadow-md p-4 text-center">
              <p className="text-gray-600">
                Your chosen word will appear here.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Loading Overlays */}
      {isAiLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white/80 z-50">
          <div className="p-4 bg-white rounded-full shadow-lg">
            <svg
              className="animate-spin h-10 w-10 text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        </div>
      )}

      {isIframeLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white/80 z-50">
          <div className="p-4 bg-white rounded-full shadow-lg">
            <svg
              className="animate-spin h-10 w-10 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dictionary;
